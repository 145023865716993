import React, {Component} from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import locales from '../../locales';
import ModalVideo from 'react-modal-video';

class SliderOne extends Component {
  constructor() {
    super();

    this.state = {
      isVideoOpen: false,
    };

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({isVideoOpen: true});
  }

  render() {
    return (
      <div className="slider-activation">
        {/* Start Single Slide */}
        <div className="slider-paralax slide d-flex align-items-center bg_image bg_image--main-slider"
             data-black-overlay="6">
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <div className="main-slide-logo-block">
                    <img
                      src="/assets/images/polygonator-logo.png"
                      alt="Polygonator"
                      className="main-slide-logo-block__logo"
                    />
                    <div className="main-slide-logo-block__text">
                      Polygonator
                    </div>
                  </div>
                  <h1 className="title theme-gradient main-slide-title">
                    VR SYSTEM
                  </h1>
                  <h2 className="title  main-slide-subtitle">
                    We create systems of immersive simulations
                    and virtual reality technology training programs
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Single Slide */}
      </div>
    );
  }
}

export default SliderOne;
