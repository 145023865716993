import React, {Component} from "react";
import {AiOutlineCheck} from "react-icons/all";
import {FiCheck} from "react-icons/fi";

class Section5 extends Component {
    render() {
        const title = 'COMPLETED PROJECTS';

        const modules = [
            'Power grid operator in VR.',
            'The Energy and Electrification Society is an energy company in VR.',
            'Atomic Energy Corporation in VR.',
            'Automation of work in production in VR.',
            'Medical rehabilitation simulators in VR.',
            'Industrial simulators in VR.',
            'Construction of real estate objects in VR.',
        ];

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <AiOutlineCheck/>
                                        </div>
                                        <h2 className="title">{title}</h2>

                                        <ul className="list-style--1">
                                            {modules.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section5;
