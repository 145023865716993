import React, {Component} from "react";
import {BsInfo} from "react-icons/all";
import locales from "../../locales";

class Section1 extends Component {
    render() {
        const title = 'About the developer team\n';

        const descriptions = [
            'Experience in creating simulators for electric grid and energy companies',
            'Experience in registration of objects of intellectual property',
            'Our own development of unique human learning system without primary programming and 3D modeling  skills in the shortest period of time.',
            'The specialist team in our company teach "VR technology" and "3D modeling" disciplines.',
        ];

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <BsInfo/>
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        {descriptions.map(
                                            (description, index) => <p className="description" key={index}>
                                                {description}
                                            </p>
                                        )
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section1;
