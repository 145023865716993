import React, {Component} from "react";
import {FiGrid} from "react-icons/all";
import Zoom from "react-medium-image-zoom";

class Section10 extends Component {
    render() {
        const listItems = [
            {
                title: 'Resource Saving',
                description: '' +
                    'Decrease in time and ' +
                    'financial costs for ' +
                    'comparable ' +
                    'training ' +
                    'on real objects. ' +
                    'Train employees without ' +
                    'worrying ' +
                    'about ' +
                    'damaging ' +
                    'real technology'
            },
            {
                title: 'Safety',
                description: '' +
                    'Usage technologies ' +
                    'VR-simulator allows ' +
                    'users to master the material and ' +
                    'hone skills. At the same time, the ' +
                    'environment will allow him to make ' +
                    'mistakes and learn from them'
            },
            {
                title: 'Quality of education',
                description: '' +
                    'The interactivity of the educational program ' +
                    'and the absence of distractions ensure the ' +
                    'completeness and depth of the study of the material'
            },
            {
                title: 'Efficiency',
                description: '' +
                    'The ability to train employees at any time and ' +
                    'even remotely. You can create template scenarios ' +
                    'and train a large number of employees'
            },
        ];

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--20 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiGrid/>
                                        </div>
                                        <h2 className="title">
                                            ADVANTAGES OF VR SIMULATORS
                                        </h2>

                                        <div className="row justify-content-center custom-columns">
                                            {listItems.map(
                                                ({title, description}, index) => <div
                                                    className="col-lg-3 col-md-6 col-sm-12 col-12 mt-3"
                                                    key={index}>
                                                    <h3>
                                                        {title}
                                                    </h3>
                                                    <p className="description">
                                                        {description}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section10;
