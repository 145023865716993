import React, {Component} from "react";
import {BsInfo} from "react-icons/all";
import locales from "../../locales";

class Section1 extends Component {
    render() {
        const title = 'GENERAL INFORMATION';

        const descriptions = [
            'Formation, maintenance of professional knowledge and skills of operational personnel, ' +
            'ensuring the required level of safety in the operation of the equipment.',
            'Obtaining skills and practicing actions for managing emergency response systems',
            'Reducing losses associated with erroneous and untimely actions of personnel.',
            'Increasing efficiency and reducing training time for operational personnel.',
            'Improving the diagnostic skills of operational personnel.',
            'Improving the safety and efficiency of production processes.',
            'Ensuring timely training of personnel during preparation and commissioning ' +
            'production processes in accordance with the rules of labor protection, industrial and ' +
            'fire safety, environmental protection in compliance with the established deadlines.'
        ];

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <BsInfo/>
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        {descriptions.map(
                                            (description, index) => <p className="description" key={index}>
                                                {description}
                                            </p>
                                        )
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section1;
