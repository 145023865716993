import React, {Component} from "react";
import {FiGrid} from "react-icons/all";
import Zoom from 'react-medium-image-zoom'

class Section10 extends Component {
    render() {
        const images = [
            '/assets/images/sections/8-1.webp',
            '/assets/images/sections/8-2.webp',
        ];

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--20 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiGrid/>
                                        </div>
                                        <h2 className="title">
                                            Automation of work in production
                                        </h2>

                                        <p className="description">
                                            Development of a virtual simulator for training in special skills for repair and replacement
                                            components and assemblies of military equipment.
                                        </p>
                                    </div>
                                    <div className="row justify-content-center custom-images-list">
                                        {images.map(
                                            (image, index) => <div
                                                className="col-lg-6 col-md-6 col-sm-12 col-12 mt-3"
                                                key={index}>
                                                <Zoom
                                                    overlayBgColorStart="rgba(0, 0, 0, 0.1)"
                                                    overlayBgColorEnd="rgba(0, 0, 0, 0.7)"
                                                >
                                                    <img
                                                        src={image}
                                                        alt=""
                                                    />
                                                </Zoom>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section10;
