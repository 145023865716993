import React, { Component } from "react";
import { FiActivity, FaHandHoldingHeart, IoMdMedical } from "react-icons/all";
import locales from "../../locales";

class ServiceOne extends Component {
    render(){
        const ServiceList = [
            {
                icon: <FiActivity />,
                title: locales.t('service-one.service-1-title'),
                description: locales.t('service-one.service-1-description'),
            },
            {
                icon: <FaHandHoldingHeart />,
                title: locales.t('service-one.service-2-title'),
                description: locales.t('service-one.service-2-description'),
            },
            {
                icon: <IoMdMedical />,
                title: locales.t('service-one.service-3-title'),
                description: locales.t('service-one.service-3-description'),
            },
        ];

        return(
            <React.Fragment>
                <div className="row justify-content-center row--25">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: val.description }}>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;